import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { BasePath } from "./../enviroments/enviroments";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UrlInterceptorService {
  baseUrl = BasePath;
  url: string;

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = req.clone({
      url: this.baseUrl + req.url,
    });

    return next.handle(request);
  }
}

import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AuthService } from "./../../_service/firebase-service/auth.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loadingAnimation: boolean = false;
  rerender = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}
  doRerender() {
    console.log("Entro aquí");
    this.rerender = true;
    this.cdRef.detectChanges();
    this.rerender = false;
  }

  async login() {
    this.loadingAnimation = true;
    this.model.action = "login";
    if (await this.authService.loginForm(this.model)) {
      localStorage.setItem("displayName", JSON.stringify("Administrador"));
      console.log("Succesfull Logged");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error en mail o password",
      });
    }
    this.loadingAnimation = false;
  }
}

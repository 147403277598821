import { Component, OnInit } from "@angular/core";
import { ControlsService } from "../../_service/controls/controls.service";
import { ActivatedRoute } from "@angular/router";
import * as html2pdf from "html2pdf.js";
import { ImpresionService } from "../../_service/impresion/impresion.service";

@Component({
  selector: "app-controles-diarios",
  templateUrl: "./controles-diarios.component.html",
  styleUrls: ["./controles-diarios.component.css"],
})
export class ControlesDiariosComponent implements OnInit {
  controls: any;

  constructor(
    private route: ActivatedRoute,
    private controlService: ControlsService,
    private ImpresionService: ImpresionService
  ) {
    this.ImpresionService.userTablaControlesDiarios = this.exportPdf;
  }

  ngOnInit() {
    let idPatient = this.route.snapshot.paramMap.get("id");
    this.controlService.getAllControls(idPatient).subscribe((resp) => {
      // console.log("Hola los controle son:",resp);
      this.controls = resp;
    });
  }

  exportPdf() {
    const options = {
      margin: [10, 10, 10, 10],
      filename: "controlesDiarios",
      image: { type: "jpeg" },
      html2canvas: { scale: 6 },
      jsPDF: { orientation: "landscape", format: "a4", unit: "mm" },
    };
    const content: Element = document.getElementById("elemtToExport4");

    html2pdf().from(content).set(options).save();

    console.log("sadkjfbsakjdbjkasd");
  }
}

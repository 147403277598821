import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { EmergencyResponse } from "../../_models/emergency";
import { ServercontrolService } from "../servercontrol/servercontrol.service";

@Injectable({
  providedIn: "root",
})
export class EmergencysService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private servercontrol: ServercontrolService
  ) {}

  //Add new Emergency

  addEmergency(data): Observable<EmergencyResponse> {
    return this.http.post<EmergencyResponse>("/emergencys", data);
  }

  // Get All Emergencys

  getAllEmergencys(params): Observable<any> {
    return this.http.get<any>("/emergencys", { params });
  }

  // Get one emergency by id

  getEmergency(id: string): Observable<any> {
    //console.log(id);
    return this.http.get<any>("/emergencys/" + id);
  }

  getEmergencyByMedic(uid, params): Observable<any> {
    return this.http.get<any>("/emergencys/medic/" + uid, { params });
  }

  //PUT  Update Emergency

  modifyEmergency(data): Observable<any> {
    console.log("La data es", data);
    const id = data.id;
    return this.http.put<any>("/emergencys/" + id, data);
  }
}
/*

  }

  // Get All Profiles

  getAllProfiles(): Observable<ProfileResponse[]> {
    const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http
            .get<ProfileResponse[]>(this.basePath,{headers})
            .pipe(
              catchError(this.handleError)
            );
  }
// Get one profile by id

  getProfile(id: string) : Observable<ProfileResponse>  {
    console.log(id);
     const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
     return this.http
      .get<ProfileResponse>( this.basePath + '/' + id ,{ headers })
       .pipe(
         catchError(this.handleError)
       ); 
  }

//PUT  Update profile

modifyProfile(data) : Observable<ProfileResponse> {
  const id= data.id;
  const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
  return this.http
    .put<ProfileResponse>(this.basePath + '/' + id,  data, { headers })
    .pipe(
      catchError(this.handleError)
    );}





} */

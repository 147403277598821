import { Component, OnInit } from "@angular/core";
import { PatientResponse } from "./../../_models/patient";
import { PatientsService } from "./../../_service/patients/patients.service";
import { HttpParams } from "@angular/common/http";
// import { EmergencysService } from '../../_service/emergencys/emergencys.service';

@Component({
  selector: "app-patients-table",
  templateUrl: "./patients-table.component.html",
  styleUrls: ["./patients-table.component.css"],
})
export class PatientsTableComponent implements OnInit {
  patients: PatientResponse[];
  patientsNotLimit: PatientResponse[];
  savePatients: any;
  userFilter: any = { dniPatient: "" };
  modelSearch: string = "";
  totalPatients: number;
  modelofiltro: string = "";
  // patientState: any = []

  pruebaSemaforo = 2;

  // Paginación y ordenamiento
  search = "";
  currentPage = 1;
  params = new HttpParams();
  totalPagination = 0;
  totalPages: any = [];
  byStatus = false;
  sortBy = {
    lastname: 1,
    dni: 1,
    firstname: 1,
  };

  constructor(
    private patientService: PatientsService,
    // private emergencyService: EmergencysService
  ) {
    console.log("Holaa es:" + this.userFilter);
  }

  getPatients() {
    this.setParam("status", "7");
      this.patientService.getAllPatients(this.params).subscribe((resp) => {
        this.patients = resp.patients;
        console.log("Los pacientes son", resp.patients);
        this.totalPatients = resp.patients.length;
        this.totalPagination = resp.totalPages;
        this.totalPages = [...Array(resp.totalPages).keys()];
      });

    // this.emergencyService.getAllEmergencys(this.params).subscribe((resp) => {
    //   for (let i in resp.emergencys) {
    //     this.patientState.push(resp.emergencys[i].state) 
    //   }
    //   console.log(this.patientState);
    // });
  }

  getPatientsBystatus() {
    console.log(this.currentPage);
      this.patientService.getAllPatients(this.params).subscribe((resp) => {
        this.patients = resp.patients;
        console.log("Los pacientes son", resp.patients);
        this.totalPatients = resp.patients.length;
        this.totalPagination = resp.totalPages;
        this.totalPages = [...Array(resp.totalPages).keys()];
      });
  }

  showMessageStatus(value: string) {
    if (value == "0") {
      return "Sin control";
    } else if (value == "1") {
      return "Correcto";
    } else if (value == "2") {
      return "Aceptable";
    } else if (value == "3") {
      return "Precaución";
    } else if (value == "4") {
      return "Alto Riesgo";
    } else if (value == "5") {
      return "Peligro";
    }
  }

  searchBy(value: string) {
    if (value == "all") {
      this.byStatus = false;
    } else {
      this.byStatus = true;
    }
    console.log(this.byStatus);

    this.currentPage = 1;
    this.params = this.params.delete("numPage");
    this.setParam("status", value);
    this.patientService.getAllPatients(this.params).subscribe((resp) => {
      console.log("Hola los pacientesssss soooooooooon:", resp);
      this.patients = resp.patients;
      this.totalPagination = resp.totalPages;
      console.log(resp.totalPages);
      this.totalPages = [...Array(resp.totalPages).keys()];
    });
  }

  //********--------------Paginación y búsqueda----------------------********

  sortRow(sortBy: string) {
    this.sortBy[sortBy] = this.sortBy[sortBy] === 1 ? 0 : 1;
    this.setParam("order" + sortBy, this.sortBy[sortBy]);
    this.getPatients();
  }

  sortArrowDirection(by: number) {
    return by === 1 ? "fa-sort-down" : "fa-sort-up";
  }

  numberPage(numberPage: string) {
    console.log("entro a la pagina 2");
    this.currentPage = parseInt(numberPage);
    this.setParam("numPage", numberPage);
    if (this.byStatus == false) {
      this.getPatients();
    } else {
      this.getPatientsBystatus();
    }
  }

  setParam(name: string, value: string) {
    if (name.includes("order")) {
      this.params = this.params.delete("orderlastname");
      this.params = this.params.delete("orderfirstname");
      this.params = this.params.delete("orderdni");
    }
    this.params = this.params.set(name, value);
    console.log(this.params);
  }

  previousPage() {
    this.currentPage--;
    this.numberPage(this.currentPage.toString());
  }

  nextPage() {
    this.currentPage++;
    this.numberPage(this.currentPage.toString());
  }

  getPatientsNotLimit() {
    const params = new HttpParams().set("limit", "10000").set("status", "7");
      this.patientService.getAllPatients(params).subscribe((res) => {
        this.patientsNotLimit = res.patients;
      });
  }

  ngOnInit() {
    console.log("jony", this.patients);

    this.getPatients();
    this.getPatientsNotLimit();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from './../app/_service/enviroments/enviroments';

import { AuthInterceptorsService } from './../app/_service/interceptors/authInterceptors.service';
import { UrlInterceptorService } from './../app/_service/interceptors/urlInterceptors';
import { HandleErrorInterceptorService } from './../app/_service/interceptors/handleErrorsInterceptors';


import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './_component/login/login.component';
import { DashboardComponent } from './_component/dashboard/dashboard.component';
import { RegisterComponent } from './_component/register/register.component';
import { PatientsTableComponent } from './_component/patients-table/patients-table.component';
import { FichaPacienteComponent } from './_component/ficha-paciente/ficha-paciente.component';
import { DatosPacienteComponent } from './_component/datos-paciente/datos-paciente.component';
import { ForgotPasswordComponent } from "./_component/forgot-password/forgot-password.component";
import { HistoriaClinicaComponent } from './_component/historia-clinica/historia-clinica.component';
import { TratamientoComponent } from './_component/tratamiento/tratamiento.component';
import { ControlesDiariosComponent } from "./_component/controles-diarios/controles-diarios.component";
import { FilterPipeModule } from 'ngx-filter-pipe';
import { EmergencyTableComponent } from './_component/emergency-table/emergency-table.component';
import { UsersTableComponent } from './_component/users-table/users-table.component';
import { NewUsserComponent } from './_component/new-usser/new-usser.component';
import { filterPipe } from './pipes/filter.pipe';
import { filterPipeUsers } from './pipes/filterUsers.pipe';
import { filterPipeEmergencies } from './pipes/filterEmergencies.pipe';
import { DashboardMedicComponent } from './_component/dashboard-medic/dashboard-medic.component';
import { UsersTableMedicComponent } from './_component/users-table-medic/users-table-medic.component';
import { PatientsTableMedicComponent } from './_component/patients-table-medic/patients-table-medic.component';
import { NewUsserMedicComponent } from './_component/new-usser-medic/new-usser-medic.component';
import { SearchPatientsPipe } from './../app/pipes/search-patients.pipe';

export function jwtTokenGetter(){
  return localStorage.getItem('accessToken');
}


@NgModule({
  declarations: [
    AppComponent,
    
    LoginComponent,
    DashboardComponent,
    PatientsTableComponent,
    RegisterComponent,
    FichaPacienteComponent,
    DatosPacienteComponent, 
    ForgotPasswordComponent, 
    HistoriaClinicaComponent, 
    TratamientoComponent, 
    ControlesDiariosComponent, 
    EmergencyTableComponent, 
    UsersTableComponent, 
    NewUsserComponent, 
    filterPipe, 
    filterPipeUsers,
    filterPipeEmergencies,
    SearchPatientsPipe,
    DashboardMedicComponent,
    UsersTableMedicComponent,
    PatientsTableMedicComponent,
    NewUsserMedicComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FilterPipeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ['localhost'],
        blacklistedRoutes: ['localhost/auth/login']
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorsService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptorService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: HandleErrorInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServercontrolService } from '../servercontrol/servercontrol.service';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {
 
  

  constructor(
    private router: Router,
    private http: HttpClient,
    private servercontrol: ServercontrolService
  ) { }

 


  // GET all controls
  getAllControls(userId: string): Observable<any[]> {
    return this.http
      .get<any[]>( '/controls/' + userId);
     
  }

}

import { Injectable,NgZone } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
//import from ''

@Injectable({
  providedIn: 'root'
})
export class HandleErrorInterceptorService {

  constructor(private router: Router,
    private ngZone: NgZone) { }

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
return next.handle(req)
.pipe(catchError(err => {
  if (err.status === 404) {
    return throwError(err.error);
  }
  if (err.status === 401) {
    this.ngZone.run(() => {this.router.navigate(['/login'])});
    //this.router.navigate(['/login']);
    return throwError(err.error);
  }
  if (err.error.mensaje) {
      // Swal.fire('Error', err.error.message, 'error')
      // return throwError(err.error);
  }
  // Swal.fire('Error', err.error.message, 'error')
  // return throwError(err);
}));
}

}

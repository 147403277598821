import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { Role } from './_guards/role.guard';
import { DashboardComponent } from './_component/dashboard/dashboard.component';
import { DashboardMedicComponent } from './_component/dashboard-medic/dashboard-medic.component';
import { LoginComponent } from './_component/login/login.component';
import { RegisterComponent } from './_component/register/register.component';
import { PatientsTableComponent } from "./_component/patients-table/patients-table.component";
import { PatientsTableMedicComponent } from "./_component/patients-table-medic/patients-table-medic.component";
import { FichaPacienteComponent } from "./_component/ficha-paciente/ficha-paciente.component";
import { ForgotPasswordComponent } from './_component/forgot-password/forgot-password.component';
import { HistoriaClinicaComponent } from './_component/historia-clinica/historia-clinica.component';
import { EmergencyTableComponent } from './_component/emergency-table/emergency-table.component';
import { UsersTableComponent } from './_component/users-table/users-table.component';
import { UsersTableMedicComponent } from './_component/users-table-medic/users-table-medic.component';
import { NewUsserComponent } from './_component/new-usser/new-usser.component';
import { NewUsserMedicComponent } from './_component/new-usser-medic/new-usser-medic.component';

 
const routes: Routes = [
  { path: 'dashboard', 
    component: DashboardComponent,
    children: [
      { path:'', pathMatch:'full', redirectTo:'patients-table' },
      {path: 'patients-table', component: PatientsTableComponent},
      {path: 'ficha-paciente/:id', component: FichaPacienteComponent},
      {path: 'historia-clinica', component: HistoriaClinicaComponent},
      { path: 'emergency-table', component: EmergencyTableComponent},
      { path: 'users-table', component: UsersTableComponent},
      { path: 'new-usser', component:NewUsserComponent },
    ],
    canActivateChild:[ AuthGuard]  
  },
  { path: 'dashboard-medic', 
    component: DashboardMedicComponent,
    children: [
      { path:'', pathMatch:'full', redirectTo:'patients-table', },
      {path: 'patients-table', component: PatientsTableMedicComponent},
      {path: 'ficha-paciente/:id', component: FichaPacienteComponent},
      {path: 'historia-clinica', component: HistoriaClinicaComponent},
      { path: 'emergency-table', component: EmergencyTableComponent},
      { path: 'users-table', component: UsersTableMedicComponent},
      { path: 'new-usser', component:NewUsserMedicComponent },
    ],
    canActivateChild:[ AuthGuard ]  
  },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterEmergencies",
})
export class filterPipeEmergencies implements PipeTransform {
  transform(emergencys: any[], search: string): any[] {
    if (search.length === 0) {
      return emergencys;
    }

    search = search.toLowerCase();
    return emergencys.filter((emergency) => {
      return (
        emergency.namePatient.toLowerCase().includes(search) ||
        emergency.state.toLowerCase().includes(search)
      );
    });
  }
}

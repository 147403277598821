import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class filterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPost = [];

    for(const post of value ){
     if(post.firstNamePatient.toLowerCase().indexOf(arg.toLowerCase()) > -1 || 
              post.dniPatient.toLowerCase().indexOf(arg.toLowerCase()) > -1 || 
         post.lastNamePatient.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
              post.celPatient.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
       post.doctor.nameDoctor.toLowerCase().indexOf(arg.toLowerCase()) > -1 
         ){
       resultPost.push(post);
     }
    }
    return resultPost;
  }

}

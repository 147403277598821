import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImpresionService {

  userTableDatos : Function;
  userTableHistoriaClinica : Function;
  userTablaTratamiento : Function;
  userTablaControlesDiarios : Function;

  constructor() { }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import {AuthService } from './../firebase-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorsService implements HttpInterceptor{

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request = req;
    let token = localStorage.getItem('accessToken');

    if (token) {
      request = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);

  }


}

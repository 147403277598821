import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUsers'
})
export class filterPipeUsers implements PipeTransform {

  transform(users: any[], search: string): any[] {
      if(search.length === 0) {return users;}

      search = search.toLowerCase();

      return users.filter(user =>{
          return user.lastName.toLowerCase().includes(search)
          || user.firstName.toLowerCase().includes(search)
         // || user.dni.toLowerCase().includes(search)
        
      }
      )

}
}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PatientResponse } from "./../../_models/patient";
import { PatientsService } from "./../../_service/patients/patients.service";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { formatDate } from "@angular/common";
import { AuthService } from "../../_service/firebase-service/auth.service";
import * as html2pdf from "html2pdf.js";
import { ImpresionService } from "../../_service/impresion/impresion.service";
import { ProfilesService } from "../../_service/profiles/profiles.service";
import { DebugHelper } from "protractor/built/debugger";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-datos-paciente",
  templateUrl: "./datos-paciente.component.html",
  styleUrls: ["./datos-paciente.component.css"],
})
export class DatosPacienteComponent implements OnInit {
  id: string;
  patient: any; //paciente obtenido de la consulta al back
  allPatients: any; //variable para rellenar el dropdown de médicos
  patientModify: any;
  fechaActual: any;
  alertModifyConfirm: boolean = true;

  forma: FormGroup;

  allDoctors: any;

  doctor: boolean = false;

  medic = false;

  age;

  @Output() visibilidadBtnSiguiente: EventEmitter<boolean>;

  banderaDisabled = true;
  banderaBtnModificar = false;
  banderaBtnCancelar = false;
  banderaBtnSiguiente = false;
  banderaBtnConfirmarCambios = false;
  banderaModalEditar = true;
  banderCambiosConfirmados = false;
  confirmado: any;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientsService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private profileService: ProfilesService,
    private impresionService: ImpresionService
  ) {
    this.visibilidadBtnSiguiente = new EventEmitter();
    this.impresionService.userTableDatos = this.exportPdf;

    const params = new HttpParams().set("limit", "10000").set("status", "7");
    this.patientService.getAllPatients(params).subscribe((patients) => {
      this.allPatients = patients;
      console.log("Todos los pacientes", this.allPatients);
    });

    this.getDoctors();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.patientService.getPatient(this.id).subscribe((resp) => {
      this.patient = resp;
      console.log("El paciente es", this.patient);
      this.patientModify = resp;

      this.forma = this.formBuilder.group({
        family: this.formBuilder.group({
          jobPhoneFamily: [
            { value: "", disabled: true },
            [
              Validators.minLength(10),
              Validators.maxLength(10),
              Validators.pattern("[0-9]*"),
            ],
          ],
          nameFamily: [
            { value: "", disabled: true },
            [
              Validators.required,
              Validators.pattern(
                "[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}"
              ),
            ],
          ],
          celFamily: [
            { value: "", disabled: true },
            [
              Validators.minLength(10),
              Validators.maxLength(10),
              Validators.pattern("[0-9]*"),
            ],
          ],
          phoneFamily: [
            { value: "", disabled: true },
            [
              Validators.minLength(10),
              Validators.maxLength(10),
              Validators.pattern("[0-9]*"),
            ],
          ],
          mailFamily: [
            { value: "", disabled: true },
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
            ],
          ],
        }),

        // 'doctor': this.formBuilder.group({
        //   'nameDoctor': [{ value: '', disabled: true }, [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]],
        //   'mailDoctor': [{ value: '', disabled: true }, [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]],
        //   'phoneDoctor': [{ value: '', disabled: true }, [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]],
        //   'placeDoctor': [{ value: '', disabled: true }, [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]],
        doctor: this.formBuilder.group({
          nameDoctor: [{ value: "", disabled: true }, [Validators.required]],
          //'lastNameDoctor': [{ value: '', disabled: true }, [Validators.required, Validators.pattern("")]],
          mailDoctor: [{ value: "", disabled: true }, [Validators.required]],
          phoneDoctor: [{ value: "", disabled: true }, [Validators.required]],
          placeDoctor: [{ value: "", disabled: true }, [Validators.required]],
          uid: [{ value: "", disabled: true }],
        }),

        userId: [""],
        uid: [""],
        statePatient: [""],
        dniPatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"),
          ],
        ],
        firstNamePatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern(
              "[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}"
            ),
          ],
        ],
        lastNamePatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern(
              "[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}"
            ),
          ],
        ],
        agePatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"),
          ],
        ],
        mailPatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          ],
        ],
        celPatient: [
          { value: "", disabled: true },
          [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("[0-9]*"),
          ],
        ],
        addressPatient: [{ value: "", disabled: true }, Validators.required],
        bornDatePatient: [{ value: "", disabled: true }, [Validators.required]],
        provincePatient: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.pattern(
              "[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}"
            ),
          ],
        ],
      });

      // if (this.patient.bornDatePatient != "" && this.patient.bornDatePatientoSring().length > 5) {
      //   if (this.patient.bornDatePatien {
      //     if(this.patient.bornDatePatientoSring().length<9){
      //       this.fechaActual = formatDate(new Date(this.patient.bornDatePatien* 100000), 'yyyy-MM-dd', 'en');
      //     }else{
      //       this.fechaActual = formatDate(new Date(this.patient.bornDatePatien* 100), 'yyyy-MM-dd', 'en');
      //     }
      //   } else {
      //     this.fechaActual = formatDate(new Date(this.patient.bornDatePatient), 'yyyy-MM-dd', 'en');
      //   }
      // }

      if (this.patient.bornDatePatient != "") {
        this.fechaActual = formatDate(
          new Date(this.patient.bornDatePatient),
          "yyyy-MM-dd",
          "en"
        );
        this.age = this.calculateAge(this.fechaActual);
      } else {
        this.fechaActual = "";
      }

      this.forma.patchValue(this.patient);
      this.forma.get("celPatient").patchValue("");
      this.forma.get("celPatient").patchValue(this.patient.celPatient);
      this.forma.get("agePatient").patchValue(this.age);

      this.forma.get("bornDatePatient").patchValue(this.fechaActual);

      console.log("El form esss", this.forma);
    });

    let role = JSON.parse(localStorage.getItem("role"));
    if (role === "medico") {
      this.medic = true;
      console.log(this.medic, "hola");
    }
  }

  selectDoctor(doct: any) {
    console.log(doct);
    this.forma
      .get("doctor")
      ["controls"]["nameDoctor"].patchValue(
        doct.firstName + " " + doct.lastName
      );
    //this.forma.get('doctor')['controls']['lastNameDoctor'].patchValue(doct.lastName)
    this.forma.get("doctor")["controls"]["mailDoctor"].patchValue(doct.email);
    this.forma
      .get("doctor")
      ["controls"]["phoneDoctor"].patchValue(doct.phoneNumber);
    this.forma
      .get("doctor")
      ["controls"]["placeDoctor"].patchValue(doct.placeDoctor);
    this.forma.get("doctor")["controls"]["uid"].patchValue(doct.uid);
    this.doctor = true;
    console.log("El form es", this.forma);
  }

  takeValueTomodify() {
    console.log("El form esss", this.forma.value);

    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguiente.emit(!this.banderaBtnSiguiente);
    this.banderaModalEditar = false;

    //this.patientModify = this.forma.value;

    this.saveValues();

    //Modificación de la fecha del paciente modificado

    let fechaString;

    if (this.patientModify.bornDatePatient) {
      fechaString = this.patientModify.bornDatePatient;
    } else {
      fechaString = this.patientModify.bornDatePatie;
    }
    let fechaMoment = moment(fechaString, "YYYY-MM-DD");
    let fechaSegundos = fechaMoment.toDate().valueOf();

    console.log(fechaMoment);

    let b;
    let a = new Date(fechaSegundos);
    a.setHours(0);
    a.setMinutes(0);
    a.setSeconds(0);

    b = a;

    console.log("Bbbbbbbbbbbbbbbbbbb ess", b.valueOf());

    this.patientModify.bornDatePatient = b;

    this.metodTomodifyBack(this.patientModify);

    this.forma.controls["dniPatient"].disable();
    this.forma.controls["firstNamePatient"].disable();
    this.forma.controls["bornDatePatient"].disable();
    this.forma.controls["lastNamePatient"].disable();
    this.forma.controls["agePatient"].disable();
    this.forma.controls["mailPatient"].disable();
    this.forma.controls["celPatient"].disable();
    this.forma.controls["addressPatient"].disable();
    this.forma.controls["provincePatient"].disable();
    this.forma.get("doctor")["controls"]["nameDoctor"].disable();
    this.forma.get("doctor")["controls"]["mailDoctor"].disable();
    this.forma.get("doctor")["controls"]["phoneDoctor"].disable();
    this.forma.get("doctor")["controls"]["placeDoctor"].disable();
    this.forma.get("family")["controls"]["jobPhoneFamily"].disable();
    this.forma.get("family")["controls"]["nameFamily"].disable();
    this.forma.get("family")["controls"]["celFamily"].disable();
    this.forma.get("family")["controls"]["mailFamily"].disable();
    this.forma.get("family")["controls"]["phoneFamily"].disable();
  }

  btnModificar() {
    this.banderaDisabled = false;
    this.banderaBtnModificar = true;
    this.banderaBtnCancelar = true;
    this.banderaBtnConfirmarCambios = true;
    this.visibilidadBtnSiguiente.emit(this.banderaBtnSiguiente);
    this.forma.controls["dniPatient"].enable();
    this.forma.controls["firstNamePatient"].enable();
    this.forma.controls["bornDatePatient"].enable();
    this.forma.controls["lastNamePatient"].enable();
    // this.forma.controls['agePatient'].enable();
    this.forma.controls["mailPatient"].enable();
    this.forma.controls["celPatient"].enable();
    this.forma.controls["addressPatient"].enable();
    this.forma.controls["provincePatient"].enable();
    this.forma.get("family")["controls"]["jobPhoneFamily"].enable();
    this.forma.get("family")["controls"]["nameFamily"].enable();
    this.forma.get("family")["controls"]["celFamily"].enable();
    this.forma.get("family")["controls"]["mailFamily"].enable();
    this.forma.get("family")["controls"]["phoneFamily"].enable();
  }

  btnCancelar() {
    this.forma.reset(this.patient);
    this.forma.get("celPatient").patchValue("");
    this.forma.get("celPatient").patchValue(this.patient.celPatient.substr(3));

    if (this.patient.bornDatePatien) {
      this.forma
        .get("bornDatePatient")
        .patchValue(moment(this.patient.bornDatePatien).format("YYYY-MM-DD"));
    } else {
      this.forma
        .get("bornDatePatient")
        .patchValue(moment(this.patient.bornDatePatien).format("YYYY-MM-DD"));
    }

    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguiente.emit(!this.banderaBtnSiguiente);

    this.forma.controls["dniPatient"].disable();
    this.forma.controls["firstNamePatient"].disable();
    this.forma.controls["bornDatePatient"].disable();
    this.forma.controls["lastNamePatient"].disable();
    this.forma.controls["agePatient"].disable();
    this.forma.controls["mailPatient"].disable();
    this.forma.controls["celPatient"].disable();
    this.forma.controls["addressPatient"].disable();
    this.forma.controls["provincePatient"].disable();
    this.forma.get("doctor")["controls"]["nameDoctor"].disable();
    this.forma.get("doctor")["controls"]["mailDoctor"].disable();
    this.forma.get("doctor")["controls"]["phoneDoctor"].disable();
    this.forma.get("doctor")["controls"]["placeDoctor"].disable();
    this.forma.get("family")["controls"]["jobPhoneFamily"].disable();
    this.forma.get("family")["controls"]["nameFamily"].disable();
    this.forma.get("family")["controls"]["celFamily"].disable();
    this.forma.get("family")["controls"]["mailFamily"].disable();
    this.forma.get("family")["controls"]["phoneFamily"].disable();
    console.log(this.forma.valid);
  }

  metodTomodifyBack(patientModify) {
    let alertModify = document.getElementById("alertModify");
    this.patientService.modifyPatient(patientModify).subscribe(
      (response) => {
        alertModify.classList.add("d-flex");
        if (response) {
          this.alertModifyConfirm = false;
          setTimeout(() => {
            this.alertModifyConfirm = true;
            alertModify.classList.remove("d-flex");
          }, 3000);
        }
      },
      (error) => {
        console.log("sale mal");
      }
    );
  }

  getDoctors() {
    console.log("Probandopoo");
    this.profileService.getDoctors().subscribe(
      (resp) => {
        console.log("HOALALALALALALALALAALALALALAL");
        this.allDoctors = resp;
        console.log("Los  doc sooooooooooon: ", resp);
      },
      (error) => {
        console.log("PROBANDO EL ERROR");
      }
    );
  }

  saveValues() {
    this.patientModify = {
      userId: this.forma.value.userId,
      uid: this.forma.value.uid,
      statePatient: this.forma.value.statePatient,
      dniPatient: this.forma.value.dniPatient,
      firstNamePatient: this.forma.value.firstNamePatient,
      lastNamePatient: this.forma.value.lastNamePatient,
      agePatient: this.forma.controls.agePatient.value,
      bornDatePatient: this.forma.value.bornDatePatient,
      mailPatient: this.forma.value.mailPatient,
      celPatient: this.forma.value.celPatient,
      addressPatient: this.forma.value.addressPatient,
      provincePatient: this.forma.value.provincePatient,
      family: this.forma.value.family,
      doctor: this.forma.controls.doctor.value,
    };

    console.log("El doc pac a modfic es", this.patientModify);
  }

  exportPdf() {
    const options = {
      margin: 10,
      filename: "paciente",
      pagebreak: { mode: "avoid-all" },
      image: { type: "jpeg" },
      html2canvas: { scale: 6 },
      jsPDF: { orientation: "landscape", format: "a4", unit: "mm" },
    };
    const content: Element = document.getElementById("elemtToExport");

    html2pdf().from(content).set(options).save();
  }

  calculateAge(value) {
    console.log("El evento es", value);
    let fechaMoment = moment(value, "YYYY-MM-DD");
    let fechafinal = fechaMoment.toDate().valueOf();

    let hoy = new Date();
    let cumpleanos = new Date(fechafinal);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    console.log("la edad es", edad);
    return edad;
  }

  ageChange(value) {
    console.log("El evento es", value);
    let fechaMoment = moment(value, "YYYY-MM-DD");
    let fechafinal = fechaMoment.toDate().valueOf();

    let hoy = new Date();
    let cumpleanos = new Date(fechafinal);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    console.log("La formas es", this.forma);
    console.log("la edad es", edad);
    this.forma.get("agePatient").patchValue(edad);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfilesService } from './../../_service/profiles/profiles.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';



@Component({
  selector: 'app-new-usser',
  templateUrl: './new-usser.component.html',
  styleUrls: ['./new-usser.component.css']
})
export class NewUsserComponent implements OnInit {
  forma: any = {};
  validationFormUser: any;
  alertModifyConfirm: boolean = true;

  modalConfirmation = false;
  flagRole: boolean = false;


  constructor(private profilesService: ProfilesService,
    private router: Router,private _location: Location,private r:ActivatedRoute) {

    this.forma = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
      'email': new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
      'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'repeatPassword': new FormControl(''),
      'role': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', [Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern('[0-9]*')]),
      'placeDoctor': new FormControl(''),
      'dni': new FormControl('', [Validators.required, Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
      'uidDoctor':new FormControl('')
    })

    this.forma.controls['repeatPassword'].setValidators([
      Validators.required,
      Validators.minLength(6),
      this.validacion.bind(this.forma)
    ])

  }





  validacion(control: FormControl): { [s: string]: boolean } {

    console.log(this)

    let forma: any = this

    if (forma.controls['password'].value !== forma.controls['repeatPassword'].value) {
      return {
        diferente: true
      }
    }

    return null

  }

  ngOnInit() {
    console.log(this.forma);

  }

  returnToTableUser() {
    //this.router.navigate(['/dashboard/users-table']);
    this.router.navigate(["../users-table"], { relativeTo: this.r });
  }

  selectRole(value: string) {
    if (value == "medico") {
      this.flagRole = true;
      this.forma.get('placeDoctor').setValidators(Validators.required);
    } else {
      this.flagRole = false;
      this.forma.get('placeDoctor').clearValidators();
      this.forma.get('placeDoctor').updateValueAndValidity();
      this.forma.get('placeDoctor').reset();
    }
    console.log("La bandera es: ", this.flagRole)
  }

  newProfiles() {

  console.log(this.forma.value);
    let newUsser = this.forma.value;
    newUsser.phoneNumber = "+54" + newUsser.phoneNumber;
    delete newUsser.repeatPassword;
    console.log(newUsser);
    console.log("entro al metodo new profiles");

    this.profilesService.addProfile(newUsser).subscribe(response => {
      
      console.log('aqui deberia entrar');
      this.alertModifyConfirm = false;
        this.alertModifyConfirm = true;
        this.returnToTableUser();
    }, error => {});
   
  }
  



}

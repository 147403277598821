import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../_service/firebase-service/auth.service';
import {Router} from '@angular/router';

declare var $:any;

@Component({
  selector: 'app-dashboard-medic',
  templateUrl: './dashboard-medic.component.html',
  styleUrls: ['./dashboard-medic.component.css']
})
export class DashboardMedicComponent implements OnInit {

  name : string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ){ }

  logOut(){
    localStorage.clear();
    this.router.navigate(['/login']);
//     this.authService.logOut().subscribe(() => {
//      localStorage.clear();
//      this.router.navigate(['/login']);
//    }, error => {
//     console.error("serompio");
//     console.error(error);
//  });
 }

  ngOnInit() {
  
    this.name = localStorage.getItem('displayName');
    "use strict"; // Start of use strict

  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  // $(document).on('scroll', function() {
  //   var scrollDistance = $(this).scrollTop();
  //   if (scrollDistance > 100) {
  //     $('.scroll-to-top').fadeIn();
  //   } else {
  //     $('.scroll-to-top').fadeOut();
  //   }
  // });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
  });


  }

}

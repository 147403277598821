import { Pipe, PipeTransform } from "@angular/core";
import { PatientResponse } from "./../_models/patient";

@Pipe({
  name: "searchPatients",
})
export class SearchPatientsPipe implements PipeTransform {
  transform(patients: PatientResponse[], search: string): PatientResponse[] {
    if (search.length === 0) {
      return patients;
    }

    search = search.toLowerCase();

    return patients.filter((patient) => {
      return (
        patient.lastNamePatient.toLowerCase().includes(search) ||
        patient.dniPatient.toLowerCase().includes(search) ||
        patient.firstNamePatient.toLowerCase().includes(search)
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProfileResponse } from '../../_models/profile';
import { ServercontrolService } from '../servercontrol/servercontrol.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  // API path
  basePath: string;
  
  constructor(
    private router: Router,
    private http: HttpClient,
    private servercontrol: ServercontrolService
  ) {}


  

  //Add new Profile

  addProfile(data): Observable<ProfileResponse> {
    return this.http
      .post<ProfileResponse>('/profiles/', data);

  }

  // Get All Profiles

  getAllProfiles(role, params): Observable<any> {
    return this.http
      .get<any>('/profiles/role/'+role, {params});
      
  }

  getDoctors(): Observable<any[]> {
    return this.http
      .get<any[]>( '/profiles/medics')
     
  }

  // Get one profile by id

  getProfile(id: string): Observable<ProfileResponse> {
    console.log(id);
    return this.http
      .get<ProfileResponse>( '/profiles/' + id);
     
  }

  //PUT  Update profile

  modifyProfile(data): Observable<ProfileResponse> {
    const id = data.id;
    return this.http
      .put<ProfileResponse>('/profiles/' + id, data);
      
  }

  deleteProfile(data): Observable<any> {
    console.log("E id es", data)
    const id = data.id;
    return this.http
      .delete<any>('/profiles/' + id);
      
  }

  modifyPassword(data): Observable<ProfileResponse> {
    console.log(data);
    return this.http
      .put<ProfileResponse>( '/profiles/updatePassword', data);
     
  }




}
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ImpresionService } from "../../_service/impresion/impresion.service";
import { PatientsService } from "./../../_service/patients/patients.service";
import * as html2pdf from "html2pdf.js";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MedicalRecordsService } from "src/app/_service/medicalRecords/medical-records.service";

@Component({
  selector: "app-historia-clinica",
  templateUrl: "./historia-clinica.component.html",
  styleUrls: ["./historia-clinica.component.css"],
})
export class HistoriaClinicaComponent implements OnInit {
  @Output() visibilidadBtnSiguienteHistoriaClinica: EventEmitter<boolean>;

  forma: FormGroup;

  banderaDisabled = true;
  banderaBtnModificar = false;
  banderaBtnCancelar = false;
  banderaBtnSiguiente = false;
  banderaBtnConfirmarCambios = false;
  banderaModalEditar = true;
  banderCambiosConfirmados = false;
  alertModifyConfirm: boolean = true;
  oldValues: any;
  htmlToAdd: any;
  noIsquemica: boolean = false;
  namePatient: string;

  constructor(
    private impresionService: ImpresionService,
    private medicalRecordsService: MedicalRecordsService,
    private route: ActivatedRoute,
    private patientService: PatientsService
  ) {
    this.visibilidadBtnSiguienteHistoriaClinica = new EventEmitter();
    this.impresionService.userTableHistoriaClinica = this.exportPdf;
  }

  historiaClinica: any = {};

  ngOnInit() {
    this.forma = new FormGroup({
      userId: new FormControl(""),

      diagnostic: new FormControl({ value: "", disabled: true }),
      fevi: new FormControl({ value: "", disabled: true }),
      renalFunction: new FormControl({ value: "", disabled: true }),

      rhythm: new FormControl({ value: "", disabled: true }),
      bloodPressure: new FormControl({ value: "", disabled: true }),
      observation: new FormControl({ value: "", disabled: true }),

      etiologiOne: new FormControl({ value: "", disabled: true }),
      etiologiTwo: new FormControl({ value: "", disabled: true }),
      others: new FormControl({ value: "", disabled: true }), //CAMPO DATE = OTROS SE DEBE CAMBIAR EL NOMBRE  DE LA VARIABLE
    });

    let id = this.route.snapshot.paramMap.get("id");
    this.medicalRecordsService.getMedicalRecord(id).subscribe((resp) => {
      this.oldValues = resp;
      if (resp.etiologiOne == "noi") {
        this.noIsquemica = true;
      }

      this.forma.patchValue(resp);
      console.log(resp);
    });

    // Busco el nombre del paciente llevarlo al html
    let idPatient = this.route.snapshot.paramMap.get("id");
    this.patientService.getPatient(idPatient).subscribe((resp) => {
      this.namePatient = resp.firstNamePatient + " " + resp.lastNamePatient;
    });
  }

  saveModifications() {
    let medic = this.forma.value;

    this.medicalRecordsService.updMedicalRecord(medic).subscribe(
      (response) => {},
      (error) => {
        console.log("entra aquiiii please");
        if (
          error != "Backend returned code 0, body was: [object ProgressEvent]"
        ) {
          this.alertModifyConfirm = false;
            this.alertModifyConfirm = true;
        }
      }
    );

    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguienteHistoriaClinica.emit(!this.banderaBtnSiguiente);
    this.banderaModalEditar = false;

    this.forma.controls["diagnostic"].disable();
    this.forma.controls["fevi"].disable();
    this.forma.controls["renalFunction"].disable();
    this.forma.controls["rhythm"].disable();
    this.forma.controls["bloodPressure"].disable();
    this.forma.controls["observation"].disable();
    this.forma.controls["etiologiOne"].disable();
    this.forma.controls["etiologiTwo"].disable();
    this.forma.controls["others"].disable();
  }

  btnModificar() {
    this.banderaDisabled = false;
    this.banderaBtnModificar = true;
    this.banderaBtnCancelar = true;
    this.banderaBtnConfirmarCambios = true;
    this.visibilidadBtnSiguienteHistoriaClinica.emit(this.banderaBtnSiguiente);
    this.forma.controls["diagnostic"].enable();
    this.forma.controls["fevi"].enable();
    this.forma.controls["renalFunction"].enable();
    this.forma.controls["rhythm"].enable();
    this.forma.controls["bloodPressure"].enable();
    this.forma.controls["observation"].enable();
    this.forma.controls["etiologiOne"].enable();
    this.forma.controls["etiologiTwo"].enable();
    this.forma.controls["others"].enable();
  }

  btnCancelar() {
    this.forma.reset(this.oldValues);

    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguienteHistoriaClinica.emit(!this.banderaBtnSiguiente);
    this.forma.controls["diagnostic"].disable();
    this.forma.controls["fevi"].disable();
    this.forma.controls["renalFunction"].disable();
    this.forma.controls["rhythm"].disable();
    this.forma.controls["bloodPressure"].disable();
    this.forma.controls["observation"].disable();
    this.forma.controls["etiologiOne"].disable();
    this.forma.controls["etiologiTwo"].disable();
    this.forma.controls["others"].disable();
  }

  selectEtiology(value: string) {
    if (value == "noi") {
      this.noIsquemica = true;
    } else {
      this.noIsquemica = false;
    }
  }

  exportPdf() {
    const options = {
      margin: 5,
      filename: "historiaClinica",
      image: { type: "jpeg" },
      html2canvas: { scale: 5 },
      jsPDF: { orientation: "landscape", format: "a4", unit: "mm" },
    };
    const content: Element = document.getElementById("elemtToExport2");

    html2pdf().from(content).set(options).save();

    console.log("Buenasss");
  }
}

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { PatientResponse } from "../../_models/patient";
import { ServercontrolService } from "../servercontrol/servercontrol.service";
import { async } from "@angular/core/testing";

@Injectable({
  providedIn: "root",
})
export class PatientsService {
  // API path

  basePath: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private servercontrol: ServercontrolService
  ) {}

  // GET one patient
  getPatient(userId: string): Observable<PatientResponse> {
    console.log(userId);
    return this.http.get<PatientResponse>("/patients/" + userId);
  }

  getPatientslByStatus(status: any): Observable<any> {
    console.log("HOLALALA CONTROLES");
    console.log(status);
    return this.http.get<any>("/patients/status/" + status);
  }

  // GET all patients
  getAllPatients(params): Observable<any> {
    return this.http.get<any>("/patients", { params });
  }
  
  //PUT one patient
  modifyPatient(data): Observable<PatientResponse> {
    const userId = data.userId;
    return this.http.put<PatientResponse>("/patients/user/" + userId, data);
  }

  modifyStatePatient(estado, uid): Observable<any> {
    const id = uid + "/" + estado;

    return this.http.put<any>("/patients/state/" + id, {});
  }

  getPatientsDoctor(pUid, params): Observable<any> {
    const uid = pUid;

    console.log("HOLALALA datos", status, uid);
    console.log(status);
    return this.http.get<any>("/patients/medic/" + uid, { params });
  }
}

import { Component, OnInit } from '@angular/core';
import {PatientResponse} from './../../_models/patient';
import { PatientsService } from './../../_service/patients/patients.service';
import { ProfilesService } from '../../_service/profiles/profiles.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
declare var $:any;


@Component({
  selector: 'app-patients-table-medic',
  templateUrl: './patients-table-medic.component.html',
  styleUrls: ['./patients-table-medic.component.css']
})
export class PatientsTableMedicComponent implements OnInit {

  patients: PatientResponse[];
  patientsNotLimit: PatientResponse[];
  savePatients: any;
  userFilter: any = {dniPatient:''};
  modelSearch: string = '';
  totalPatients:number;
  modelofiltro:string = '';
  patientToDelete: any;
  deletePatient:any;
  id: string;



  deleteUserFlag = false;

  pruebaSemaforo = 2;

  // Paginación y ordenamiento
  search = "";
  currentPage = 1;
  params = new HttpParams();
  totalPagination = 0;
  totalPages: any = [];
  byStatus = false;
  sortBy = {
    name: 1,
    dni: 1
  };

 

  constructor(
    private patientService : PatientsService,
    private profileService: ProfilesService,
    private router: Router
    
  ) {console.log("Holaa es:"+this.userFilter) }



  getPatients(){
    const uid = localStorage.getItem('uid');
    this.setParam('status',"7");
    console.log ('params:', this.params);
     this.patientService.getPatientsDoctor(uid,this.params).subscribe(
       resp => {
        console.log("Los pacientes son paciente es",resp);
           this.patients = resp.patients;
           this.totalPagination = resp.totalPages;
           this.totalPages = [...Array(resp.totalPages).keys()]
         }
       )
  }
     

  /* search(value:string){
    if( this.modelSearch.length > 2){
    console.log(this.modelSearch);

    //Aquí se debe incluir la llamada al servicio para mandar la palabra a buscar
    //La respuesta debe modificar la variable modelSearch

    }
  } */

  showMessageStatus(value:string){
    if(value == '0'){
      return "Sin control";
    }else if(value == '1'){
      return "Correcto";
    }else if(value == '2'){
      return "Aceptable";
    }else if(value == '3'){
      return  "Observable-Precaución"
    }else if(value == '4'){
      return "Precaución";
    }else if(value == '5'){
      return "Peligro"
    }
  }
  
  searchBy(value: string) {

    if(value == "all"){
      this.byStatus = false;
    }else{
      this.byStatus = true;
    }
    this. currentPage = 1;
    this.params = this.params.delete('numPage');
    this.setParam('status', value);
    const uid = localStorage.getItem('uid')
      this.patientService.getPatientsDoctor(uid,this.params).subscribe(
        resp => {
           console.log("Hola los pacientesssss soooooooooon:",resp);
            this.patients = resp.patients;
            this.totalPagination = resp.totalPages;
            console.log(resp.totalPages);
            this.totalPages = [...Array(resp.totalPages).keys()]
          }
        )

    
    
  }

  returnUserToDelete(patient){
    this.patientToDelete = patient.firstNamePatient +" "+patient.lastNamePatient;
    this.deletePatient = patient;
  }

  deleteUser(){
    this.profileService.deleteProfile({id:this.deletePatient.userId}).subscribe((resp)=>{
      this.deleteUserFlag = true;
        this.dismisModal();
        this.refreshEditUser();
     
    })
  }

  refreshEditUser() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard-medic/patients-table']);
    return;
  }

  dismisModal(){
    $('#mdDelete').modal('hide');
  }

  changeState(estado,paciente){
    //this.oneEmergency.state = estado;
    
    this.patientService.modifyStatePatient(estado,paciente.userId).subscribe(response => {
      console.log("holaaa");
    }, error => {
      console.error("salemalll");
    }); 
  }

  //********--------------Paginación y búsqueda----------------------********

  sortRow(sortBy: string) {
    this.sortBy[sortBy] = this.sortBy[sortBy] === 1 ? 0 : 1;
    this.setParam('order' + sortBy, this.sortBy[sortBy]);
    this.getPatients();
  }

  sortArrowDirection(by: number) {
    return by === 1 ? 'fa-sort-down' : 'fa-sort-up';
  }


  numberPage(numberPage: string) {
    console.log("entro a la pagina 2");
    this.currentPage = parseInt(numberPage);
    this.setParam('numPage', numberPage);
    this.getPatients();
  }

  setParam(name: string, value: string) {
    if (name.includes('order')) {
      this.params = this.params.delete('ordername');
      this.params = this.params.delete('orderdni');
    }
    this.params = this.params.set(name, value);
    console.log(this.params);
  }

  previousPage() {
    this.currentPage--;
    this.numberPage(this.currentPage.toString());
  }

  nextPage() {
    this.currentPage++;
    this.numberPage(this.currentPage.toString());
  }

  getPatientsNotLimit(){
    const uid = localStorage.getItem('uid');
    const params = new HttpParams().set('limit','10000').set('status','7');
      this.patientService.getPatientsDoctor(uid, params).subscribe( res => {
        console.log("Los pacientes son paciente es",res);
        this.patientsNotLimit = res.patients;
      })
    
  }

  ngOnInit() {
    this.getPatients();
    this.getPatientsNotLimit();
  } 





}

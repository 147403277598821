import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServercontrolService {

  herokuVariable : boolean = true;

  constructor(){ }

  takeValue(){
    return this.herokuVariable;
  }

}

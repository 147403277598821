import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { medicalRecordResponse } from '../../_models/medicalRecord';
import { ServercontrolService } from '../servercontrol/servercontrol.service';

@Injectable({
  providedIn: 'root'
})

export class MedicalRecordsService {
 
constructor(
  private router: Router,
  private http: HttpClient,
  private servercontrol: ServercontrolService
) {  }

 

//Add new Medical Record

addMedicalRecord(data): Observable<medicalRecordResponse> {
  return this.http
         .post<medicalRecordResponse>('/medicalRecords/', data);
         
}
// GET One Medical Record by userId
getMedicalRecord(userId: string) : Observable<medicalRecordResponse>{
  //console.log(userId);
   return this.http
    .get<medicalRecordResponse>( '/medicalRecords/' + userId );
    
}

//PUT  Update MedicalRecord

updMedicalRecord(data) : Observable<medicalRecordResponse> {
  const userId= data.userId;
  return this.http
    .put<medicalRecordResponse>( '/medicalRecords/' + userId,  data);
    }


}


/*
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EmergencyResponse } from '../../_models/emergency'
import { ServercontrolService } from '../servercontrol/servercontrol.service';

@Injectable({
  providedIn: 'root'
})
export class EmergencysService {
// API path
basePath = 'http://localhost:4000/emergencys';
  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

   // Http Options
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization: ''
    })
  };

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  //Add new Emergency

  addEmergency(data): Observable<EmergencyResponse> {
    const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http
           .post<EmergencyResponse>(this.basePath, data, {headers})
           .pipe(
             catchError(this.handleError)
           );


  }

  // Get All Emergencys

  getAllEmergencys(): Observable<EmergencyResponse[]> {
    const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http
            .get<EmergencyResponse[]>(this.basePath,{headers})
            .pipe(
              catchError(this.handleError)
            );
  }

  // Get one emergency by id

  getEmergency(id: string) : Observable<EmergencyResponse>{
    console.log(id);
     const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
     return this.http
      .get<EmergencyResponse>( this.basePath + '/' + id ,{ headers })
       .pipe(
         catchError(this.handleError)
       );
  }

  //PUT  Update Emergency

  modifyEmergency(data) : Observable<EmergencyResponse> {
    const id= data.id;
    const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http
      .put<EmergencyResponse>(this.basePath + '/' + id,  data, { headers })
      .pipe(
        catchError(this.handleError)
    );}



}
/*

  }

  // Get All Profiles

  getAllProfiles(): Observable<ProfileResponse[]> {
    const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http
            .get<ProfileResponse[]>(this.basePath,{headers})
            .pipe(
              catchError(this.handleError)
            );
  }
// Get one profile by id

  getProfile(id: string) : Observable<ProfileResponse>  {
    console.log(id);
     const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
     return this.http
      .get<ProfileResponse>( this.basePath + '/' + id ,{ headers })
       .pipe(
         catchError(this.handleError)
       ); 
  }

//PUT  Update profile

modifyProfile(data) : Observable<ProfileResponse> {
  const id= data.id;
  const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
  return this.http
    .put<ProfileResponse>(this.basePath + '/' + id,  data, { headers })
    .pipe(
      catchError(this.handleError)
    );}





} */ 
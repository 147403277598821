import { Component, OnInit } from '@angular/core';
import { ProfileResponse } from './../../_models/profile';
import { ProfilesService } from './../../_service/profiles/profiles.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from "@angular/router";

@Component({
  selector: 'app-users-table-medic',
  templateUrl: './users-table-medic.component.html',
  styleUrls: ['./users-table-medic.component.css']
})
export class UsersTableMedicComponent implements OnInit {

  profiles: ProfileResponse[];
  modelSearch: string;
  user: any;
  userModify: any;
  forma: FormGroup;
  validationFormUser: any;
  alertModifyConfirm: boolean = true;
  confirmEditUser: boolean = false;

  saveProfiles: any;

  model = '';

  constructor(private profileService: ProfilesService,
    private router: Router) {

  }

  ngOnInit() {
    // this.profileService.getAllProfiles().subscribe(
    //   profiles => {
    //     this.profiles = profiles;
    //     this.saveProfiles = profiles;
    //     console.log(profiles);
    //   }
    // )
  }

  searchBy(value: string) {

    this.profiles = this.saveProfiles;
    let profiles = this.profiles;
    console.log(profiles);
    console.log(value)

    if (value == 'all') {
      this.profiles = profiles;

    }
    if (value == 'admin') {
      this.profiles = [];
      for (const prof of profiles) {
        if (prof.role == 'administrador') {
          this.profiles.push(prof);
        }
      }
      console.log(this.profiles);

    }
    if (value == 'doctor') {
      this.profiles = [];
      for (const prof of profiles) {
        if (prof.role == 'medico') {
          this.profiles.push(prof);
        }
      }
      console.log(this.profiles);

    }
    if (value == 'patient') {
      this.profiles = [];
      for (const prof of profiles) {
        if (prof.role == 'paciente') {
          this.profiles.push(prof);
        }
      }
      console.log(this.profiles);

    }
    if (value == 'family') {
      this.profiles = [];
      for (const prof of profiles) {
        if (prof.role == 'familiar') {
          this.profiles.push(prof);
        }
      }
      console.log(this.profiles);

    }
  }

  search() {
    if (this.modelSearch.length > 2) {
      console.log(this.modelSearch);

      //Aquí se debe incluir la llamada al servicio para mandar la palabra a buscar
      //La respuesta debe modificar la variable modelSearch

    }
  }


  //Obtenemos un usuario en el modal para luego modificarlo
  getData(id: string) {
    this.profileService.getProfile(id).subscribe(
      (resp) => {

        this.user = resp;

        let userModified = resp; //Creo la variable para luego asignarle el valor repeatPassword y poder usar setValue
       
        let userAddedProperty = Object.defineProperty(userModified, 'repeatPassword', { value: userModified.password });


        this.forma = new FormGroup({
          'id': new FormControl(''),
          'firstName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
          'lastName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
          'email': new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
          'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
          'repeatPassword': new FormControl(),
          'role': new FormControl('', Validators.required),
          'phoneNumber': new FormControl('', Validators.required),
          'uploadDate': new FormControl('')

        });

       
        this.forma.patchValue(userAddedProperty);

        this.forma.controls['repeatPassword'].setValidators([
          Validators.required,
          Validators.minLength(6),
          this.validacion.bind(this.forma)
        ])



      })
  }

  validacion(control: FormControl): { [s: string]: boolean } {

    console.log(this)

    let forma: any = this

    if (forma.controls['password'].value !== forma.controls['repeatPassword'].value) {
      return {
        diferente: true
      }
    }

    return null

  }

  emptyRepeatPass(){
    if(this.forma.controls['repeatPassword'].value == null){
      return true;
   
    }
    
  }

  modifyUser() {
    this.confirmEditUser = true
    this.userModify = this.forma.value;
    delete this.userModify.repeatPassword;
    this.alertModifyConfirm = false;

    this.saveChanges(this.userModify);

    setTimeout(() => {

      this.alertModifyConfirm = true;

    }, 3000);



  }

  //Guardamos los cambios del usuario caundo presionamos el boton del modal
  saveChanges(data) {
    this.profileService.modifyProfile(data).subscribe(response => {


    }, error => {
      console.log("sale mal");
    })
  }

  cambiarfecha(profile) {

    if (profile.uploadDate) {
      let fecha2 = new Date(profile.uploadDate._seconds * 1000);
      moment.locale('es');
      let fecha1 = moment(fecha2).format('D MMMM YYYY , h:mm a').toLowerCase();
      return fecha1;
    }

  }

  refreshEditUser() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard/users-table']);
    return;
  }

}

import { Component, OnInit } from "@angular/core";
import { ImpresionService } from "../../_service/impresion/impresion.service";

@Component({
  selector: "app-ficha-paciente",
  templateUrl: "./ficha-paciente.component.html",
  styleUrls: ["./ficha-paciente.component.css"],
})
export class FichaPacienteComponent implements OnInit {
  btnSiguiente: boolean = true;
  btnSiguienteHistoriaClinica: boolean = true;
  btnSiguienteTratamiento: boolean = true;

  constructor(private impresionService: ImpresionService) {}

  ngOnInit() {}

  next() {
    let ficha1 = document.getElementById("ficha1");
    // let ficha2 = document.getElementById('ficha2');
    // let ficha3 = document.getElementById('ficha3');
    let ficha4 = document.getElementById("ficha4");
    let seccion1 = document.getElementById("seccion1");
    // let seccion2 = document.getElementById('seccion2');
    // let seccion3 = document.getElementById('seccion3');
    let seccion4 = document.getElementById("seccion4");
    let btnVolver = document.getElementById("btnVolver");

    if (
      ficha1.className == "nav-link active" &&
      seccion1.className == "tab-pane active"
    ) {
      ficha1.classList.remove("active");
      ficha4.classList.add("active");
      seccion1.classList.remove("active");
      seccion4.classList.add("active");
      btnVolver.classList.remove("d-none");
    }
    // }else
    // if(ficha2.className == 'nav-link active' && seccion2.className == 'tab-pane active'){
    //   ficha2.classList.remove('active');
    //   ficha3.classList.add('active');
    //   seccion2.classList.remove('active');
    //   seccion3.classList.add('active');

    // }else
    // if(ficha3.className == 'nav-link active' && seccion3.className == 'tab-pane active'){
    //   ficha3.classList.remove('active');
    //   ficha4.classList.add('active');
    //   seccion3.classList.remove('active');
    //   seccion4.classList.add('active');

    // }
  }

  previous() {
    let ficha1 = document.getElementById("ficha1");
    // let ficha2 = document.getElementById('ficha2');
    // let ficha3 = document.getElementById('ficha3');
    let ficha4 = document.getElementById("ficha4");
    let seccion1 = document.getElementById("seccion1");
    // let seccion2 = document.getElementById('seccion2');
    // let seccion3 = document.getElementById('seccion3');
    let seccion4 = document.getElementById("seccion4");
    let btnVolver = document.getElementById("btnVolver");

    if (
      ficha4.className == "nav-link active" &&
      seccion4.className == "tab-pane active"
    ) {
      ficha4.classList.remove("active");
      ficha1.classList.add("active");
      seccion4.classList.remove("active");
      seccion1.classList.add("active");
      btnVolver.classList.add("d-none");
    }
    // else
    // if(ficha3.className == 'nav-link active' && seccion3.className == 'tab-pane active'){
    //   ficha3.classList.remove('active');
    //   ficha2.classList.add('active');
    //   seccion3.classList.remove('active');
    //   seccion2.classList.add('active');

    // }else
    // if(ficha4.className == 'nav-link active' && seccion4.className == 'tab-pane active'){
    //   ficha4.classList.remove('active');
    //   ficha3.classList.add('active');
    //   seccion4.classList.remove('active');
    //   seccion3.classList.add('active');

    // }
  }

  btnHideUno() {
    let ficha1 = document.getElementById("ficha1");
    let btnVolver = document.getElementById("btnVolver");

    btnVolver.classList.add("d-none");
  }

  btnHideDos() {
    let ficha2 = document.getElementById("ficha2");
    let btnVolver = document.getElementById("btnVolver");

    btnVolver.classList.remove("d-none");
  }

  btnHideTres() {
    let ficha2 = document.getElementById("ficha2");
    let btnVolver = document.getElementById("btnVolver");

    btnVolver.classList.remove("d-none");
  }

  btnHideCuatro() {
    let ficha2 = document.getElementById("ficha2");
    let btnVolver = document.getElementById("btnVolver");

    btnVolver.classList.remove("d-none");
  }

  modificarbtnSiguiente(item: boolean) {
    this.btnSiguiente = item;
  }

  modificarbtnSiguienteHistoriaClinica(item: boolean) {
    this.btnSiguienteHistoriaClinica = item;
  }

  modificarbtnSiguienteTratamiento(item: boolean) {
    this.btnSiguienteTratamiento = item;
  }

  btnImprimir() {
    this.impresionService.userTableDatos();
    this.impresionService.userTableHistoriaClinica();
    this.impresionService.userTablaTratamiento();
    this.impresionService.userTablaControlesDiarios();
    console.log("Holaa");
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    //Desarrollo
    // apiKey: "AIzaSyDG0uSSrqcuyXkrUr1gtaj63cGLJQ2d8Q8",
    // authDomain: "stepmed-test.firebaseapp.com",
    // projectId: "stepmed-test",
    // storageBucket: "stepmed-test.appspot.com",
    // messagingSenderId: "806102226917",
    // appId: "1:806102226917:web:f57497c1a4f8ba7f91810c",

    //Produccion
    apiKey: "AIzaSyBOF1VZIeXIMbVw8aOI-i6vJQ8W3PwRTt0",
    authDomain: "stepmed-app-95539.firebaseapp.com",
    databaseURL: "https://stepmed-app-95539.firebaseio.com",
    projectId: "stepmed-app-95539",
    storageBucket: "stepmed-app-95539.appspot.com",
    messagingSenderId: "663077274973",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from "@angular/core";
import { EmergencyResponse } from "./../../_models/emergency";
import { EmergencysService } from "./../../_service/emergencys/emergencys.service";
import { HttpParams } from "@angular/common/http";
import { PatientsService } from "../../_service/patients/patients.service";
@Component({
  selector: "app-emergency-table",
  templateUrl: "./emergency-table.component.html",
  styleUrls: ["./emergency-table.component.css"],
})
export class EmergencyTableComponent implements OnInit {
  emergencys: any[];
  emergencysNotLimit: any[];
  oneEmergency: any;
  modelSearch: string = "";
  emergencyFilter: any = { id: "" };
  admin: boolean;

  observation: string;
  state: string;
  search = "";
  prueba = this.obtainState();

  // Paginación y ordenamiento

  currentPage = 1;
  params = new HttpParams();
  totalPagination = 0;
  totalPages: any = [];
  sortBy = {
    paciente: 1,
    doctor: 1,
  };

  constructor(
    private emergencyService: EmergencysService,
    private patientService: PatientsService
  ) {}

  ngOnInit() {
    this.getEmergencies();
    this.getEmergencysNotLimit();
  }

  getEmergencies() {
    if (localStorage.getItem("role") == "administrador") {
      this.emergencyService.getAllEmergencys(this.params).subscribe((resp) => {
        // this.emergencys = (this.emergencys.sort((a,b)=> b.date._seconds  - a.date._seconds))
        console.log("Las emergencias son", resp.emergencys);
        console.log("las paginas ", resp.totalPages);
        console.log("Hola los emergencias soooooooooon:", resp);
        this.emergencys = resp.emergencys;
        this.totalPagination = resp.totalPages;
        this.totalPages = [...Array(resp.totalPages).keys()];
      });
    }
    if (localStorage.getItem("role") == "medico") {
      const uid = localStorage.getItem("uid");
      this.emergencyService
        .getEmergencyByMedic(uid, this.params)
        .subscribe((resp) => {
          //  this.emergencys = (this.emergencys.sort((a,b)=> b.date._seconds  - a.date._seconds))
          console.log("Las emergencias son", resp.emergencys);
          this.emergencys = resp.emergencys;
          this.totalPagination = resp.totalPages;
          this.totalPages = [...Array(resp.totalPages).keys()];
        });
    }
  }

  obtainObservation(emer) {
    this.observation = emer.observations;
    this.oneEmergency = emer;
  }

  addObservation() {
    this.oneEmergency.observations = this.observation;
    this.emergencyService.modifyEmergency(this.oneEmergency).subscribe(
      (response) => {
        console.log("holaaa");
      },
      (error) => {
        console.error("salemalll");
      }
    );
  }

  //Esta funcion hace que cuando se selecciona un estado, al instante este se guarda en la DB
  addState(emergency) {
    this.emergencyService.modifyEmergency(emergency).subscribe();
  }

  obtainState() {
    this.patientService.getPatient("").subscribe((resp) => {});

    return "adios";
    //this.oneEmergency.state = estado;
    // console.log("El estado y la emerg son: ",estado," ",emergencia);
    // emergencia.state = estado;
    // console.log("L emergencia modificadad es: ",emergencia)
    // this.emergencyService.modifyEmergency(emergencia).subscribe(response => {
    //   console.log("holaaa");
    // }, error => {
    //   console.error("salemalll");
    // });
  }

  //Paginación

  sortRow(sortBy: string) {
    this.sortBy[sortBy] = this.sortBy[sortBy] === 1 ? 0 : 1;
    this.setParam("order" + sortBy, this.sortBy[sortBy]);
    this.getEmergencies();
  }

  sortArrowDirection(by: number) {
    return by === 1 ? "fa-sort-down" : "fa-sort-up";
  }

  numberPage(numberPage: string) {
    console.log("entro a la pagina 2");
    this.currentPage = parseInt(numberPage);
    this.setParam("numPage", numberPage);
    this.getEmergencies();
  }

  setParam(name: string, value: string) {
    if (name.includes("order")) {
      this.params = this.params.delete("orderpaciente");
      this.params = this.params.delete("orderdoctor");
    }
    this.params = this.params.set(name, value);
    console.log(this.params);
  }

  previousPage() {
    this.currentPage--;
    this.numberPage(this.currentPage.toString());
  }

  nextPage() {
    this.currentPage++;
    this.numberPage(this.currentPage.toString());
  }

  getEmergencysNotLimit() {
    const params = new HttpParams().set("limit", "10000");
    if (localStorage.getItem("role") == "administrador") {
      this.emergencyService.getAllEmergencys(params).subscribe((resp) => {
        this.emergencysNotLimit = resp.emergencys;
        console.log(this.emergencysNotLimit);
        this.admin = true;
      });
    }
    if (localStorage.getItem("role") == "medico") {
      this.admin = false;
      const uid = localStorage.getItem("uid");
      this.emergencyService
        .getEmergencyByMedic(uid, params)
        .subscribe((resp) => {
          this.emergencysNotLimit = resp.emergencys;
        });
    }
  }
}

import { Injectable } from "@angular/core";
import { CanActivateChild, Router } from "@angular/router";
import { AuthService } from "./../_service/firebase-service/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivateChild() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["/auth/login"]);
      return false;
    } else {
      return true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ProfileResponse } from './../../_models/profile';
import { ProfilesService } from './../../_service/profiles/profiles.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from "@angular/router";
declare var $:any;
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css']
})
export class UsersTableComponent implements OnInit {

  users: any[];
  usersNotLimit:any[];
  modelSearch: string;
  user: any;
  userModify: any;
  forma: FormGroup;
  formPas: FormGroup;
  validationFormUser: any;
  alertModifyConfirm: boolean = true;
  confirmEditUser: boolean = false;
  alertModifyPassConfirm: boolean = true;
  confirmEditPass: boolean = false;
  userToDelete: any;

  profileTodelete: any;
  deleteUserFlag = false;

  saveProfiles: any;

  model = '';
  role = "all";
  // Paginación y ordenamiento
  search = "";
  currentPage = 1;
  params = new HttpParams();
  totalPagination = 0;
  totalPages: any = [];
  sortBy = {
    name: 1,
    email: 1
  };
  byRole =false;
  roleToSearch="";

  constructor(private profileService: ProfilesService,
    private router: Router) {

  }


  getUsers(){
    this.profileService.getAllProfiles(this.role, this.params).subscribe(
      resp => {
        this.users = resp.profiles;
        this.totalPagination = resp.totalPages;
        this.totalPages = [...Array(resp.totalPages).keys()]
        console.log(this.users);
      }
    )
  }
  
getProfileByRole(roleToSearch){
  this.profileService.getAllProfiles(roleToSearch,this.params).subscribe(
    resp => {
       console.log("Hola los usuarios son:",resp.profiles);
        this.users = resp.profiles;
        this.totalPagination = resp.totalPages;
        this.totalPages = [...Array(resp.totalPages).keys()]
        console.log(resp.totalPages);
      }
    )
}

searchBy(value: string) {

  if(value == "all"){
    this.byRole = false;
  }else{
    this.byRole = true;
  }

  this.roleToSearch = value;
  this.params = this.params.delete('numPage');
  this.currentPage = 1;
  console.log("El role to search es",this.roleToSearch)
      this.profileService.getAllProfiles(this.roleToSearch,this.params).subscribe(
        resp => {
           console.log("Hola los usuarios son:",resp.profiles);
            this.users = resp.profiles;
            this.totalPagination = resp.totalPages;
            this.totalPages = [...Array(resp.totalPages).keys()]
            console.log(resp.totalPages);
          }
        )
  }

  


  //Obtenemos un usuario en el modal para luego modificarlo
  getData(id: string) {
    this.profileService.getProfile(id).subscribe(
      (resp) => {

       
        console.log("El usser es", resp);

        let userModified = resp; //Creo la variable para luego asignarle el valor repeatPassword y poder usar setValue

        let userAddedProperty = Object.defineProperty(userModified, 'repeatPassword', { value: userModified.password });


        this.forma = new FormGroup({
          'id': new FormControl(''),
          'dni': new FormControl(''),
          'uid': new FormControl(''),
          'firstName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
          'lastName': new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
          'email': new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
          'password': new FormControl(''),
          'repeatPassword': new FormControl(),
          'role': new FormControl('', Validators.required),
          'phoneNumber':new FormControl('', [Validators.minLength(10),Validators.maxLength(10),Validators.pattern('[0-9]*')]),
          'uploadDate': new FormControl('')
   
        });

        userAddedProperty.phoneNumber = userAddedProperty.phoneNumber.replace("+54",'');
        this.forma.patchValue(userAddedProperty);


      })
  }

  validacion(control: FormControl): { [s: string]: boolean } {

    console.log(this)

    let forma: any = this

    if (forma.controls['password'].value !== forma.controls['repeatPassword'].value) {
      return {
        diferente: true
      }
    }

    return null

  }

  emptyRepeatPass() {
    if (this.formPas.controls['repeatPassword'].value == null) {
      return true;

    }

  }

  modifyUser() {
    this.confirmEditUser = true;
    this.userModify = this.forma.value;
    delete this.userModify.repeatPassword;
   

    this.saveChanges(this.userModify);

  }

  //Guardamos los cambios del usuario caundo presionamos el boton del modal
  saveChanges(data) {
    console.log("La data a modificar es",data)
    this.profileService.modifyProfile(data).subscribe(response => {
      console.log("Holaa",response);
      this.alertModifyConfirm = false;
    }, error => {
      console.log("sale mal");
    })
  }

  cambiarfecha(profile) {

    if (profile.uploadDate) {
      let fecha2 = new Date(profile.uploadDate._seconds * 1000);
      moment.locale('es');
      let fecha1 = moment(fecha2).format('D MMMM YYYY , h:mm a').toLowerCase();
      return fecha1;
    }

  }

  refreshEditUser() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard/users-table']);
    return;
  }


  modifyPassword() {

    this.alertModifyPassConfirm = false;
    this.confirmEditPass = true;

    setTimeout(() => {

      this.alertModifyPassConfirm = true;

    }, 5000);

    const data = {
      uid: this.formPas.controls.uid.value,
      newPassword: this.formPas.controls.password.value
    }

    this.profileService.modifyPassword(data).subscribe(response => {
      console.log("Se modifica la pass exitosamente");
    }, error => {
      console.log("Algo sale mal");
    })
  }

  formPass(id: string) {

    let data;

    this.profileService.getProfile(id).subscribe(
      (resp) => {

        data = {
          uid: resp.uid
        }

        this.formPas = new FormGroup({

          'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
          'repeatPassword': new FormControl(),
          'uid': new FormControl()

        });


        this.formPas.controls['repeatPassword'].setValidators([
          Validators.required,
          Validators.minLength(6),
          this.validacion.bind(this.formPas)
        ])

        console.log(data);
        this.formPas.controls.uid.setValue(data.uid);
      })

  }
 



  returnUserToDelete(profile){
    this.profileTodelete = profile.firstName +" "+profile.lastName;
    this.userToDelete = profile;
  }

  //Método para eliminar un profile
  deleteUser(){
    this.profileService.deleteProfile(this.userToDelete).subscribe((resp)=>{
      this.deleteUserFlag = true;
      setTimeout(() => {
        this.dismisModal();
        this.refreshEditUser();
      }, 2000);
     
    })
  }

  dismisModal(){
    $('#mdDelete').modal('hide');
  }

   //********--------------Paginación y búsqueda----------------------********

  sortRow(sortBy: string) {
    this.sortBy[sortBy] = this.sortBy[sortBy] === 1 ? 0 : 1;
    this.setParam('order' + sortBy, this.sortBy[sortBy]);
    this.getUsers();
  }

  sortArrowDirection(by: number) {
    return by === 1 ? 'fa-sort-down' : 'fa-sort-up';
  }


  numberPage(numberPage: string) {
    console.log("entro a la pagina 2");
    this.currentPage = parseInt(numberPage);
    this.setParam('numPage', numberPage);
    console.log("Holalala",this.roleToSearch, this.byRole)
    if(this.byRole==false){
      this.getUsers();
    }else{
      this.getProfileByRole(this.roleToSearch);
    }
  }

  setParam(name: string, value: string) {
    if (name.includes('order')) {
      this.params = this.params.delete('ordername');
      this.params = this.params.delete('orderdni');
    }
    this.params = this.params.set(name, value);
    console.log(this.params);
  }

  previousPage() {
    this.currentPage--;
    this.numberPage(this.currentPage.toString());
  }

  nextPage() {
    this.currentPage++;
    this.numberPage(this.currentPage.toString());
  }

  getUsersNotLimit(){
    const params = new HttpParams().set('limit','10000');
      this.profileService.getAllProfiles("all",params).subscribe( res => {
        this.usersNotLimit = res.profiles;
      })
    
  }

  ngOnInit() {
    this.getUsers();
    this.getUsersNotLimit();
  } 


  

  }


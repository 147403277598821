import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import * as html2pdf from "html2pdf.js";
import { ImpresionService } from "../../_service/impresion/impresion.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TreatmentsService } from "../../_service/tratments/treatments.service";
import { PatientsService } from "./../../_service/patients/patients.service";

@Component({
  selector: "app-tratamiento",
  templateUrl: "./tratamiento.component.html",
  styleUrls: ["./tratamiento.component.css"],
})
export class TratamientoComponent implements OnInit {
  @Output() visibilidadBtnSiguienteTratamiento: EventEmitter<boolean>;

  forma: FormGroup;
  actualValues: any;

  banderaDisabled = true;
  banderaBtnModificar = false;
  banderaBtnCancelar = false;
  banderaBtnSiguiente = false;
  banderaBtnConfirmarCambios = false;
  banderaModalEditar = true;
  banderCambiosConfirmados = false;
  alertModifyConfirm: boolean = true;

  namePatient: string;

  constructor(
    private impresionService: ImpresionService,
    private route: ActivatedRoute,
    private treatmentService: TreatmentsService,
    private patientService: PatientsService
  ) {
    this.visibilidadBtnSiguienteTratamiento = new EventEmitter();
    this.impresionService.userTablaTratamiento = this.exportPdf;
  }
  ngOnInit() {
    this.forma = new FormGroup({
      userId: new FormControl(""),

      diuretic1: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
        other: new FormControl({ value: "", disabled: true }),
      }),

      diuretic2: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
        other: new FormControl({ value: "", disabled: true }),
      }),

      ieca: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
        other: new FormControl({ value: "", disabled: true }),
      }),

      betabloqueantes: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
        other: new FormControl({ value: "", disabled: true }),
      }),

      antialdosteronic: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
      }),

      digitalics: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
      }),

      antiArrhythmics: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
        other: new FormControl({ value: "", disabled: true }),
      }),

      anticoagulants: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
      }),

      others: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
        posology: new FormControl({ value: "", disabled: true }),
      }),

      dispositives: new FormGroup({
        name: new FormControl({ value: "", disabled: true }),
        dose: new FormControl({ value: "", disabled: true }),
      }),

      observations: new FormControl({ value: "", disabled: true }),
    });

    // Aquí se traen los tratamientos
    console.log(this.forma.value);
    let id = this.route.snapshot.paramMap.get("id");
    this.treatmentService.getMedicalRecord(id).subscribe((resp) => {
      console.log("Los tratamientos son: ", resp);
      this.actualValues = resp;
      this.forma.patchValue(resp);
    });

    // Busco el nombre del paciente levarlo al html
    let idPatient = this.route.snapshot.paramMap.get("id");
    this.patientService.getPatient(idPatient).subscribe((resp) => {
      this.namePatient = resp.firstNamePatient + " " + resp.lastNamePatient;
    });
  }

  takeVauleToModify() {
    console.log(this.forma.value);

    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguienteTratamiento.emit(!this.banderaBtnSiguiente);
    this.banderaModalEditar = false;

    this.treatmentService.updMedicalRecord(this.forma.value).subscribe(
      (response) => {},
      (error) => {
        if (
          error != "Backend returned code 0, body was: [object ProgressEvent]"
        ) {
          this.alertModifyConfirm = false;

          setTimeout(() => {
            this.alertModifyConfirm = true;
          }, 3000);
        }
      }
    );

    this.forma.get("diuretic1")["controls"]["name"].disable();
    this.forma.get("diuretic1")["controls"]["dose"].disable();
    this.forma.get("diuretic1")["controls"]["posology"].disable();
    this.forma.get("diuretic1")["controls"]["other"].disable();
    this.forma.get("diuretic2")["controls"]["name"].disable();
    this.forma.get("diuretic2")["controls"]["dose"].disable();
    this.forma.get("diuretic2")["controls"]["posology"].disable();
    this.forma.get("diuretic2")["controls"]["other"].disable();
    this.forma.get("ieca")["controls"]["name"].disable();
    this.forma.get("ieca")["controls"]["dose"].disable();
    this.forma.get("ieca")["controls"]["posology"].disable();
    this.forma.get("ieca")["controls"]["other"].disable();
    this.forma.get("betabloqueantes")["controls"]["name"].disable();
    this.forma.get("betabloqueantes")["controls"]["dose"].disable();
    this.forma.get("betabloqueantes")["controls"]["posology"].disable();
    this.forma.get("betabloqueantes")["controls"]["other"].disable();
    this.forma.get("antialdosteronic")["controls"]["name"].disable();
    this.forma.get("antialdosteronic")["controls"]["dose"].disable();
    this.forma.get("antialdosteronic")["controls"]["posology"].disable();
    this.forma.get("digitalics")["controls"]["name"].disable();
    this.forma.get("digitalics")["controls"]["dose"].disable();
    this.forma.get("digitalics")["controls"]["posology"].disable();
    this.forma.get("antiArrhythmics")["controls"]["name"].disable();
    this.forma.get("antiArrhythmics")["controls"]["dose"].disable();
    this.forma.get("antiArrhythmics")["controls"]["posology"].disable();
    this.forma.get("antiArrhythmics")["controls"]["other"].disable();
    this.forma.get("anticoagulants")["controls"]["name"].disable();
    this.forma.get("anticoagulants")["controls"]["dose"].disable();
    this.forma.get("anticoagulants")["controls"]["posology"].disable();
    this.forma.get("others")["controls"]["name"].disable();
    this.forma.get("others")["controls"]["dose"].disable();
    this.forma.get("others")["controls"]["posology"].disable();
    this.forma.get("dispositives")["controls"]["dose"].disable();
    this.forma.get("dispositives")["controls"]["name"].disable();
    this.forma.controls["observations"].disable();
  }

  btnModificar() {
    this.banderaDisabled = false;
    this.banderaBtnModificar = true;
    this.banderaBtnCancelar = true;
    this.banderaBtnConfirmarCambios = true;
    this.visibilidadBtnSiguienteTratamiento.emit(this.banderaBtnSiguiente);
    this.forma.get("diuretic1")["controls"]["name"].enable();
    this.forma.get("diuretic1")["controls"]["dose"].enable();
    this.forma.get("diuretic1")["controls"]["posology"].enable();
    this.forma.get("diuretic1")["controls"]["other"].enable();
    this.forma.get("diuretic2")["controls"]["name"].enable();
    this.forma.get("diuretic2")["controls"]["dose"].enable();
    this.forma.get("diuretic2")["controls"]["posology"].enable();
    this.forma.get("diuretic2")["controls"]["other"].enable();
    this.forma.get("ieca")["controls"]["name"].enable();
    this.forma.get("ieca")["controls"]["dose"].enable();
    this.forma.get("ieca")["controls"]["posology"].enable();
    this.forma.get("ieca")["controls"]["other"].enable();
    this.forma.get("betabloqueantes")["controls"]["name"].enable();
    this.forma.get("betabloqueantes")["controls"]["dose"].enable();
    this.forma.get("betabloqueantes")["controls"]["posology"].enable();
    this.forma.get("betabloqueantes")["controls"]["other"].enable();
    this.forma.get("antialdosteronic")["controls"]["name"].enable();
    this.forma.get("antialdosteronic")["controls"]["dose"].enable();
    this.forma.get("antialdosteronic")["controls"]["posology"].enable();
    this.forma.get("digitalics")["controls"]["name"].enable();
    this.forma.get("digitalics")["controls"]["dose"].enable();
    this.forma.get("digitalics")["controls"]["posology"].enable();
    this.forma.get("antiArrhythmics")["controls"]["name"].enable();
    this.forma.get("antiArrhythmics")["controls"]["dose"].enable();
    this.forma.get("antiArrhythmics")["controls"]["posology"].enable();
    this.forma.get("antiArrhythmics")["controls"]["other"].enable();
    this.forma.get("anticoagulants")["controls"]["name"].enable();
    this.forma.get("anticoagulants")["controls"]["dose"].enable();
    this.forma.get("anticoagulants")["controls"]["posology"].enable();
    this.forma.get("others")["controls"]["name"].enable();
    this.forma.get("others")["controls"]["dose"].enable();
    this.forma.get("others")["controls"]["posology"].enable();
    this.forma.get("dispositives")["controls"]["dose"].enable();
    this.forma.get("dispositives")["controls"]["name"].enable();
    this.forma.controls["observations"].enable();
  }

  btnCancelar() {
    this.forma.reset(this.actualValues);
    this.banderaDisabled = true;
    this.banderaBtnModificar = false;
    this.banderaBtnCancelar = false;
    this.banderaBtnConfirmarCambios = false;
    this.visibilidadBtnSiguienteTratamiento.emit(!this.banderaBtnSiguiente);
    this.forma.get("diuretic1")["controls"]["name"].disable();
    this.forma.get("diuretic1")["controls"]["dose"].disable();
    this.forma.get("diuretic1")["controls"]["posology"].disable();
    this.forma.get("diuretic1")["controls"]["other"].disable();
    this.forma.get("diuretic2")["controls"]["name"].disable();
    this.forma.get("diuretic2")["controls"]["dose"].disable();
    this.forma.get("diuretic2")["controls"]["posology"].disable();
    this.forma.get("diuretic2")["controls"]["other"].disable();
    this.forma.get("ieca")["controls"]["name"].disable();
    this.forma.get("ieca")["controls"]["dose"].disable();
    this.forma.get("ieca")["controls"]["posology"].disable();
    this.forma.get("betabloqueantes")["controls"]["name"].disable();
    this.forma.get("betabloqueantes")["controls"]["dose"].disable();
    this.forma.get("betabloqueantes")["controls"]["posology"].disable();
    this.forma.get("betabloqueantes")["controls"]["other"].disable();
    this.forma.get("antialdosteronic")["controls"]["name"].disable();
    this.forma.get("antialdosteronic")["controls"]["dose"].disable();
    this.forma.get("antialdosteronic")["controls"]["posology"].disable();
    this.forma.get("digitalics")["controls"]["name"].disable();
    this.forma.get("digitalics")["controls"]["dose"].disable();
    this.forma.get("digitalics")["controls"]["posology"].disable();
    this.forma.get("antiArrhythmics")["controls"]["name"].disable();
    this.forma.get("antiArrhythmics")["controls"]["dose"].disable();
    this.forma.get("antiArrhythmics")["controls"]["posology"].disable();
    this.forma.get("antiArrhythmics")["controls"]["other"].disable();
    this.forma.get("anticoagulants")["controls"]["name"].disable();
    this.forma.get("anticoagulants")["controls"]["dose"].disable();
    this.forma.get("anticoagulants")["controls"]["posology"].disable();
    this.forma.get("others")["controls"]["name"].disable();
    this.forma.get("others")["controls"]["dose"].disable();
    this.forma.get("others")["controls"]["posology"].disable();
    this.forma.get("dispositives")["controls"]["dose"].disable();
    this.forma.get("dispositives")["controls"]["name"].disable();
    this.forma.controls["observations"].disable();
  }

  exportPdf() {
    const options = {
      margin: [10, 20, 10, 20],
      pagebreak: { mode: "avoid-all", before: "#page2el" },
      filename: "tratamiento",
      image: { type: "jpeg" },
      html2canvas: { scale: 1 },
      jsPDF: { orientation: "landscape", format: "a4", unit: "mm" },
    };
    const content: Element = document.getElementById("elemtToExport3");

    html2pdf().from(content).set(options).save();
  }
}

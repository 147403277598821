import { Component, OnInit, ViewChild } from '@angular/core';
import {ProfilesService} from './../../_service/profiles/profiles.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router} from "@angular/router";




@Component({
  selector: 'app-new-usser-medic',
  templateUrl: './new-usser-medic.component.html',
  styleUrls: ['./new-usser-medic.component.css']
})
export class NewUsserMedicComponent implements OnInit {

  forma: any = {};
  validationFormUser: any;
  alertModifyConfirm:boolean = true;

    modalConfirmation = false; 


  constructor (private profilesService: ProfilesService,
                private router: Router) { 

    this.forma = new FormGroup({
        'firstName':new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
        'lastName':new FormControl('', [Validators.required, Validators.pattern("[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{0,100}")]),
        'dni':new FormControl('', [Validators.required, Validators.minLength(6)]),
        'email':new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
        'password':new FormControl('', [Validators.required, Validators.minLength(6)]),
        'repeatPassword': new FormControl(''),
        'role':new FormControl('', Validators.required),
        'phoneNumber':new FormControl('', Validators.required),
        'placeDoctor': new FormControl(''),
        'uidDoctor':new FormControl('') 
    })
  
    this.forma.controls['repeatPassword'].setValidators([
      Validators.required, 
      Validators.minLength(6),
      this.validacion.bind(this.forma)
    ])


  }

  validacion( control: FormControl): { [s:string]: boolean } {

    console.log(this)

    let forma:any = this
     
    if( forma.controls['password'].value !== forma.controls['repeatPassword'].value){
      return {
        diferente: true
      }
    }

    return null

  }

  ngOnInit() {
    console.log(this.forma);
     
  }

  returnToTableUser(){
    this.router.navigate(['/dashboard-medic/patients-table']);
  }



  newProfiles(){


    let newUsser = this.forma.value;
    newUsser.uidDoctor = localStorage.getItem('uid');
    newUsser.phoneNumber = "+54"+newUsser.phoneNumber;
    delete newUsser.repeatPassword;
    console.log(newUsser); 
    console.log("entro al metodo new profiles");
    
    this.profilesService.addProfile(newUsser).subscribe(response => {

      console.log('aqui deberia entrar');
      this.alertModifyConfirm = false;
        this.alertModifyConfirm = true;
        this.returnToTableUser();
    }, error => {});
   
  }

  

}

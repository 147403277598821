import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { TreatmentResponse } from "../../_models/treatment";
import { ServercontrolService } from "../servercontrol/servercontrol.service";

@Injectable({
  providedIn: "root",
})
export class TreatmentsService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private servercontrol: ServercontrolService
  ) {}

  //Add new Treatment Record

  addTreatment(data): Observable<TreatmentResponse> {
    return this.http.post<TreatmentResponse>("/treatments/", data);
  }

  // GET One Treatment Record by userId
  getMedicalRecord(userId: string): Observable<TreatmentResponse> {
    console.log(userId);
    return this.http.get<TreatmentResponse>("/treatments/" + userId);
  }

  //PUT  Update Treatment

  updMedicalRecord(data): Observable<TreatmentResponse> {
    const userId = data.userId;
    return this.http.put<TreatmentResponse>("/treatments/" + userId, data);
  }
}

/*
export class MedicalRecordsService {
  // API path
basePath = 'http://localhost:4000/medicalRecords';

constructor(
  private router: Router,
  private http: HttpClient,
) { }

 // Http Options
 httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
     Authorization: ''
  })
};

 // Handle API errors
 handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // return an observable with a user-facing error message
  return throwError(
    'Something bad happened; please try again later.');
}

//Add new Medical Record

addMedicalRecord(data): Observable<medicalRecordResponse> {
  const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
  return this.http
         .post<medicalRecordResponse>(this.basePath, data, {headers})
         .pipe(
           catchError(this.handleError)
         );
}
// GET One Medical Record by userId
getMedicalRecord(userId: string) : Observable<medicalRecordResponse>{
  console.log(userId);
   const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
   return this.http
    .get<medicalRecordResponse>( this.basePath + '/' + userId ,{ headers })
     .pipe(
       catchError(this.handleError)
     );
}

//PUT  Update MedicalRecord

updMedicalRecord(data) : Observable<medicalRecordResponse> {
  const userId= data.userId;
  const headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
  return this.http
    .put<medicalRecordResponse>(this.basePath + '/' + userId,  data, { headers })
    .pipe(
      catchError(this.handleError)
  );}


} */

import { Component, OnInit } from "@angular/core";
import { AuthService } from "./../../_service/firebase-service/auth.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  model: any = {};

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  register() {
    this.model.action = "register";
    this.authService.signUp(this.model).subscribe(
      (response) => {
        console.log(response);
        console.log("registro exitoso");
      },
      (error) => {
        console.error("salemal");
        console.error(error);
      }
    );
  }
}

import { Injectable, NgZone } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { LoginResponse } from "../../_models/user";
import { RegisterResponse } from "../../_models/register";
import { RoleResponse } from "./../../_models/role";
import { Router } from "@angular/router";
import { ServercontrolService } from "../servercontrol/servercontrol.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // API  path
  basePath: string;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private fireauth: AngularFireAuth,
    private servercontrol: ServercontrolService
  ) {}

  // Verify user credentials on server to get token
  loginForm(param) {
    console.log(param);
    
    return this.fireauth.auth
      .signInWithEmailAndPassword(param.email, param.password)
      .then(async (user) => {
        return await this.http
          .get<RoleResponse>("/role/" + param.email)
          .toPromise()
          .then(async (data) => {
            let role = data.role;

            let accessToken = "";
            await user.user.getIdToken().then((token) => (accessToken = token));

            this.setUser({
              uid: user.user.uid,
              token: accessToken,
              role: role,
              displayName: user.user.displayName,
            });

            return true;
          });
      })
      .catch((error) => {
        console.log(error);
        return false;
        
      });
  }

  // After login save token and other values(if any) in localStorage
  setUser(resp: any) {
    console.log("HOALALALLALA", resp);
    localStorage.setItem("name", resp.name);
    localStorage.setItem("accessToken", resp.accessToken);
    localStorage.setItem("role", resp.role);
    localStorage.setItem("displayName", resp.displayName);
    localStorage.setItem("uid", resp.uid);
    if (resp.role == "administrador") {
      this.router.navigate(["/dashboard"]);
    }
    if (resp.role == "medico") {
      this.router.navigate(["/dashboard-medic"]);
    }
  }

  // Checking if token is set
  async isLoggedIn() {
    this.fireauth.authState.subscribe(async (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          localStorage.setItem("accessToken", token);
          return true;
        });
      } else {
        return false;
      }
    });
  }

  // After clearing localStorage redirect to login screen
  logOut() {
    this.fireauth.auth.signOut().then(() => {
      localStorage.clear();
      this.ngZone.run(() => {
        this.router.navigate(["/login"]);
      });
    });
  }

  // Get data from server for Dashboard
  getData(data): Observable<LoginResponse> {
    return this.http.post<LoginResponse>("api.php", data);
  }

  /*signUp(user: LoginResponse): Observable<any> {
  let api = `${this.basePath}/auth/signup`;
  return this.http.post(api, user)
    .pipe(
      catchError(this.handleError)
    )
}*/

  // Sign up user
  signUp(data): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>("/auth/signup", data);
  }
}
